import React from 'react';
import { Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory
} from "react-router-dom";
import DocumentMeta from 'react-document-meta';


import './App.css';

const meta = {
  title: 'RMA Portal',
  favicon: "http://sstatic.net/stackoverflow/img/favicon.ico",
  description: "RMA Portal"
}

export default function App(this: any) {

  return (
    <Router>

      {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/" exact={true}>
          <Login/>
        </Route>
        <Route path="/home" exact={true}>
          <Home/>
        </Route>
      </Switch>
    </Router>
  );
}

const getEnv = (hostname: string): any => {
  if (hostname === 'localhost' || hostname.includes('firebase')){
    return 'dev-'
  }
  let urlPrefix = ''
  if (hostname.includes('dev')) {
    urlPrefix = 'dev-'
  }
  else if (hostname.includes('staging')){
    urlPrefix = 'staging-'
  }
  else {
    // SAAS-500: point firebase dev client at staging apis
    //  urlPrefix = 'staging-'
    // SAAS-516: temporarily point at dev- to test delete user
    urlPrefix = ''
  }
  return urlPrefix
}

const urlPre = getEnv( window.location.hostname)

export function Login() {
  const history = useHistory();
  sessionStorage.clear();
  function handleOnSubmit(this: any, event: any) {
    event.preventDefault()

    const data = new FormData(event.target);


    let requestData = { 'email': data.get('username'), 'password': data.get('password'), audience: 'rma-api'};

    // @ts-ignore
    fetch(`https://${urlPre}kepler-auth-svc.mersive.com/v1/auth/token`, {
      method: 'POST',
      body: JSON.stringify(requestData),
      headers : {
        'Content-Type': 'application/json',
      }
    }).then(response => {
      if (!response.ok) {
        window.alert("Error logging in, please try again")
      }
      return response.json()
    }).then(resp => {
        sessionStorage.setItem('token', resp['access_token']);
        history.replace("/home");
      })
  }


    return( <div className="App">
      <DocumentMeta {...meta} />
      <header className="App-header">
        <h2>Login to RMA Portal</h2>
        <br/>
        <Form onSubmit={(evt: any) => handleOnSubmit(evt)}>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Username</Form.Label>
            <input type="email" name='username' placeholder="Enter email" required={true} />
          </Form.Group>

          <Form.Group controlId="formBasicPassword">
            <Form.Label>Password</Form.Label>
            <input type="password" name='password' placeholder="Password"  required={true} />
          </Form.Group>
          <Button variant="primary" type="submit" active size="lg">
            Login
          </Button>{' '}
        </Form>
      </header>
    </div>);
  }

//  pass in params bia local storage
export function Home() {
  const history = useHistory();

  let token = sessionStorage.getItem('token')

  if(token === null){
    history.replace("/");
  }

  function handleOnSubmit(this: any, event: any) {
    event.preventDefault()

    let data = new FormData(event.target);

    // @ts-ignore
    let stringToSplit = data.get("pods").toString()

    // @ts-ignore
    let org = data.get("org").toString()

    if (!stringToSplit || stringToSplit === "") {
      window.alert("please enter valid input data");
      return
    }

    const arr = stringToSplit.split(",").join("\r\n").split("\r\n")
    const jsonToSend = {ids: arr, org: org}

    let rma_url = process.env.REACT_APP_RMA_URL
    if (!rma_url)
    {
      rma_url = `https://${urlPre}kepler-backend.mersive.com/delete_pods`
    }

    fetch(rma_url, {
      method: 'POST',
      body: JSON.stringify(jsonToSend),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    }).then(response => {
      if (!response.ok) {
        window.alert(`Error deleting pods with status code: ${response.status} contact the Solstice Cloud team`)
      } else {
        // @ts-ignore
        document.getElementById("pod_field").value="";
        window.alert(`Successfully deleted pods from Solstice Cloud`)
      }
      return response.status
    });
  }

  return(
    <body>
    <DocumentMeta {...meta} />
      <nav className="navbar navbar-light">
          <a href="/"
             className="btn btn-outline-dark my-2 my-sm-0 float-right logout">Logout</a>
      </nav>
      <header className="App-header">
        <h2>Enter Pod ids to remove from Solstice Cloud <br/> To be used for demo and RMA Pods only! </h2>
        <Form onSubmit={(evt: any) => handleOnSubmit(evt)}>
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>Please separate pod ids with commas</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="textarea" aria-label="With textarea" id='pod_field' name='pods' />
            <InputGroup.Prepend>
              <InputGroup.Text>Optional Org Id (only delete from this org)</InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl as="textarea" aria-label="With textarea" id='org_field' name='org' />
          </InputGroup>
          <Button variant="primary" type="submit" active size="lg">
            Send
          </Button>{' '}
        </Form>
      </header>
    </body>
  );

}
